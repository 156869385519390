import styled, { css } from "styled-components";

export const Container = styled.div`
  background: ${props =>
    props?.background === "white" ? "#FFFFFF" : "#F2F4F7"};
`

export const IconBlockFeatureSectionWrapper = styled.div`
  font-family: "Roboto", sans-serif;
  max-width: 1170px;
  margin: 0 auto;
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
`

export const FeatureSection = styled.div`
  margin: 0 auto;
  padding: 96px 30px;
  @media (max-width: 992px) {
    padding: 56px 15px;
  }
  @media (max-width: 768px) {
    padding: 40px 15px;
  }
    ${({ noTopPadding, noBottomPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    padding-bottom: ${noBottomPadding === true && 0};
      @media (max-width: 992px) {
        padding-top: ${noTopPadding === true && 0};
        padding-bottom: ${noBottomPadding === true && 0};
      }
      @media (max-width: 768px) {
        padding-top: ${noTopPadding === true && 0};
        padding-bottom: ${noBottomPadding === true && 0};
      }
  `}
`

export const HeadingContainer = styled.div`
  max-width: 770px;
  margin: 0 auto;
  padding-bottom: 24px;
`

export const Kicker = styled.div`
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #0022b7;
  text-align: center;
`

export const Heading = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: #101828;
  margin: 24px 0 0;
  text-align: center;
`

export const Subheading = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  margin: 16px 0 0;
  text-align: center;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

export const FeatureContainer = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const FeatureItem = styled.div`
  padding: 24px 16px;
  flex-grow: 1;
  width: 100%;
`

export const FeatureItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  a {
    display: inline-block;
    margin-top: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #002DC2;
  }
`

export const FeatureItemIcon = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    max-height: ${({ unrestrictImageHeight }) => unrestrictImageHeight ? 'unset' : '340px'};
  }
`

export const FeatureDescriptionWrapper = styled.div`
  max-width: 770px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
`

export const FeatureItemTitle = styled.h5`
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #101828;
  margin: 32px 0 0;
`

export const FeatureItemDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1b1b1b;
  margin: 12px 0;
`
