import React from "react"
import { IconSection } from "./FeatureIconSection"
import {
  Container,
  IconBlockFeatureSectionWrapper,
  FeatureSection,
  HeadingContainer,
  Kicker,
  Heading,
  Subheading,
  FeatureContainer,
} from "./styles/FeatureSection.styled"

const IconBlockFeatureSection = ({ component }) => {
  const { kicker, heading, subheading, icons, background, noTopPadding, noBottomPadding, unrestrictImageHeight } = component

  return (
    <Container background={background}>
      <IconBlockFeatureSectionWrapper>
        <FeatureSection noTopPadding={noTopPadding} noBottomPadding={noBottomPadding}>
          <HeadingContainer>
            <Kicker>{kicker}</Kicker>
            <Heading>{heading}</Heading>
            <Subheading>
              {subheading?.subheading}
            </Subheading>
          </HeadingContainer>
          <FeatureContainer>
            {icons?.map((icon, index) => {
              return (
                <IconSection unrestrictImageHeight={unrestrictImageHeight} icon={icon} key={index} />
              )
            })}
          </FeatureContainer>
        </FeatureSection>
      </IconBlockFeatureSectionWrapper>
    </Container>
  )
}

export default IconBlockFeatureSection
